// Import necessary dependencies and modules
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage, getTokenHeader, isCancelRequest } from "../../utils";
import evService from "./taService";

// Initial state for the ta fuel reducer
interface EVState {
    taFuelStopList: any,
    taFuelStopListLoading: boolean,
}

// Define the initial state values for the ta fuel reducer
const initialState: EVState = {
    taFuelStopList: null,
    taFuelStopListLoading: false,
}

// Define an asynchronous thunk for fetching ta fuel table data
export const getTaFuelStopList = createAsyncThunk(
    "get/ta/fuel/stop/list",
    async (userData: any, thunkApi) => {
        try {
            return await evService.getTaFuelStopListApi(
                userData,
                getTokenHeader()
            );
        } catch (error: any) {
            const message: any = getErrorMessage(error);
            return thunkApi.rejectWithValue(message);
        }
    }
);


// Define the ta fuel reducer
export const taReducer = createSlice({
    name: "ev",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder


            .addCase(getTaFuelStopList.pending, (state) => {
                state.taFuelStopList = null
                state.taFuelStopListLoading = true
            })
            .addCase(getTaFuelStopList.fulfilled, (state, action) => {
                state.taFuelStopList = action.payload;
                state.taFuelStopListLoading = false
            })
            .addCase(getTaFuelStopList.rejected, (state, action) => {
                state.taFuelStopList = null
                state.taFuelStopListLoading = isCancelRequest(action?.payload)
            })


    }
});


// Export the ta fuel reducer
export default taReducer.reducer;
