import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import localFreightService from "./localFreightService";
import { getTokenHeader, getErrorMessage, isCancelRequest } from "../../utils";
import { LocalFreightState } from "./localFreightInterface";

/**
 * Redux Slice for managing bid planning data
 */

// Define the shape of the state

// Initial state
const initialState: LocalFreightState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    isLoadingTruckFuelTypesPieChart: false,
    truckFuelTypesPieChart: null,
    isLoadingEmissionIntensityRNGBarChart: false,
    costEmissionIntensityRNGBarChart: null,
    isLoadingKeyMetricsSummaryLocalFreight: false,
    keyMetricsSummaryLocalFreight: null,
    isLoadingLaneNameLocalFreight: false,
    laneNameLocalFreight: null,
    rngFreightDate: null,
    keyMetricsAlternativeDto: null,
    keyMetricsAlternativeDtoLoading: false,
    listOfAllLanesByShipmentsDto: null,
    listOfAllLanesByShipmentsDtoLoading: false,
    lanesByFuelUsageAndMileageDto: null,
    lanesByFuelUsageAndMileageDtoLoading: false,
    lanesByFuelStackeByEmissionsDto: null,
    lanesByFuelStackeByEmissionsDtoLoading: false,
    lanesByFuelStackeByQuantityDto: null,
    lanesByFuelStackeByQuantityDtoLoading: false,
    lanesByFuelStackeByMileageDto: null,
    lanesByFuelStackeByMileageDtoLoading: false,
    totalEmissionGraphByLaneAndFuelType: null,
    totalEmissionGraphByLaneAndFuelTypeLoading: false,
    laneStatisticsDto: null,
    isLoadinglaneStatistics: false
}

export const getTruckFuelTypestPieChart = createAsyncThunk("get/truck/fuel/type/distribution/pie/chart", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getTruckFuelTypesPieChartgApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});


export const getEmissionIntensityRNGBarChart = createAsyncThunk("get/cost/impact/bar/chart/bid/planning", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getEmissionIntensityRNGBarChartApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getKeyMetricsSummaryLocalFreight = createAsyncThunk("get/key/metrics/summary/localFreight", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getKeyMetricsSummaryLocalFreightApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getLaneNameLocalFreight = createAsyncThunk("get/lane/name/localFreight", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getLaneNameLocalFreightApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getRNGFreightDate = createAsyncThunk("get/date/local/freight", async (userData: any, thunkApi) => {
    try {
        return await localFreightService.getRNGFreightDateApi(userData, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getKeyMetricsAlternative = createAsyncThunk("get/key/metrics/alternative", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getKeyMetricsDateApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getListOfAllLanesByShipments = createAsyncThunk("get/all/list/lane/alternative", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getListOfAllLanesByShipmentsApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getLanesByFuelUsageAndMileage = createAsyncThunk("get/lane/fuel/usage/and/mileage/alternative", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getLanesByFuelUsageAndMileageApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getStackedGraphByLaneAndFuelTypeEmission = createAsyncThunk("get/stack/chart/emission", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getStackedGraphByLaneAndFuelTypeApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getStackedGraphByLaneAndFuelTypeQuantity = createAsyncThunk("get/stack/chart/quantity", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getStackedGraphByLaneAndFuelTypeApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getStackedGraphByLaneAndFuelTypeMileage = createAsyncThunk("get/stack/chart/mileage", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getStackedGraphByLaneAndFuelTypeApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

export const getTotalEmissionGraphByLaneAndFuelType = createAsyncThunk("get/emission/chart/mileage", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getTotalEmissionGraphByLaneAndFuelTypeApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});


export const getLaneStatistics = createAsyncThunk("get/lane/Statistics/mileage", async (payload: any, thunkApi) => {
    try {
        return await localFreightService.getLaneStatisticsApi(payload, getTokenHeader())
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});



export const localFreightDataReducer = createSlice({
    name: "local-freight",
    initialState,
    reducers: {
        resetLocalFreight: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getKeyMetricsAlternative.pending, (state) => {
                state.keyMetricsAlternativeDtoLoading = true
                state.keyMetricsAlternativeDto = null
            })
            .addCase(getKeyMetricsAlternative.fulfilled, (state, action) => {
                state.keyMetricsAlternativeDtoLoading = false
                state.keyMetricsAlternativeDto = action.payload
            })
            .addCase(getKeyMetricsAlternative.rejected, (state, action) => {
                state.keyMetricsAlternativeDtoLoading = isCancelRequest(action?.payload)
                state.keyMetricsAlternativeDto = null
            })
            .addCase(getLaneStatistics.pending, (state) => {
                state.isLoadinglaneStatistics = true
                state.laneStatisticsDto = null
            })
            .addCase(getLaneStatistics.fulfilled, (state, action) => {
                state.isLoadinglaneStatistics = false
                state.laneStatisticsDto = action.payload
            })
            .addCase(getLaneStatistics.rejected, (state, action) => {
                state.isLoadinglaneStatistics = false
                state.laneStatisticsDto = null
            })

            
            .addCase(getTotalEmissionGraphByLaneAndFuelType.pending, (state) => {
                state.totalEmissionGraphByLaneAndFuelTypeLoading = true
                state.totalEmissionGraphByLaneAndFuelType = null
            })
            .addCase(getTotalEmissionGraphByLaneAndFuelType.fulfilled, (state, action) => {
                state.totalEmissionGraphByLaneAndFuelTypeLoading = false
                state.totalEmissionGraphByLaneAndFuelType = action.payload
            })
            .addCase(getTotalEmissionGraphByLaneAndFuelType.rejected, (state, action) => {
                state.totalEmissionGraphByLaneAndFuelTypeLoading = isCancelRequest(action?.payload)
                state.totalEmissionGraphByLaneAndFuelType = null
            })


            .addCase(getStackedGraphByLaneAndFuelTypeEmission.pending, (state) => {
                state.lanesByFuelStackeByEmissionsDtoLoading = true
                state.lanesByFuelStackeByEmissionsDto = null
            })
            .addCase(getStackedGraphByLaneAndFuelTypeEmission.fulfilled, (state, action) => {
                state.lanesByFuelStackeByEmissionsDtoLoading = false
                state.lanesByFuelStackeByEmissionsDto = action.payload
            })
            .addCase(getStackedGraphByLaneAndFuelTypeEmission.rejected, (state, action) => {
                state.lanesByFuelStackeByEmissionsDtoLoading = isCancelRequest(action?.payload)
                state.lanesByFuelStackeByEmissionsDto = null
            })
            .addCase(getStackedGraphByLaneAndFuelTypeQuantity.pending, (state) => {
                state.lanesByFuelStackeByQuantityDtoLoading = true
                state.lanesByFuelStackeByQuantityDto = null
            })
            .addCase(getStackedGraphByLaneAndFuelTypeQuantity.fulfilled, (state, action) => {
                state.lanesByFuelStackeByQuantityDtoLoading = false
                state.lanesByFuelStackeByQuantityDto = action.payload
            })
            .addCase(getStackedGraphByLaneAndFuelTypeQuantity.rejected, (state, action) => {
                state.lanesByFuelStackeByQuantityDtoLoading = isCancelRequest(action?.payload)
                state.lanesByFuelStackeByQuantityDto = null
            })

            .addCase(getStackedGraphByLaneAndFuelTypeMileage.pending, (state) => {
                state.lanesByFuelStackeByMileageDtoLoading = true
                state.lanesByFuelStackeByMileageDto = null
            })
            .addCase(getStackedGraphByLaneAndFuelTypeMileage.fulfilled, (state, action) => {
                state.lanesByFuelStackeByMileageDtoLoading = false
                state.lanesByFuelStackeByMileageDto = action.payload
            })
            .addCase(getStackedGraphByLaneAndFuelTypeMileage.rejected, (state, action) => {
                state.lanesByFuelStackeByMileageDtoLoading = isCancelRequest(action?.payload)
                state.lanesByFuelStackeByMileageDto = null
            })



            .addCase(getLanesByFuelUsageAndMileage.pending, (state) => {
                state.lanesByFuelUsageAndMileageDtoLoading = true
                state.lanesByFuelUsageAndMileageDto = null
            })
            .addCase(getLanesByFuelUsageAndMileage.fulfilled, (state, action) => {
                state.lanesByFuelUsageAndMileageDtoLoading = false
                state.lanesByFuelUsageAndMileageDto = action.payload
            })
            .addCase(getLanesByFuelUsageAndMileage.rejected, (state, action) => {
                state.lanesByFuelUsageAndMileageDtoLoading = isCancelRequest(action?.payload)
                state.lanesByFuelUsageAndMileageDto = null
            })

            .addCase(getListOfAllLanesByShipments.pending, (state) => {
                state.listOfAllLanesByShipmentsDtoLoading = true
                state.listOfAllLanesByShipmentsDto = null
            })
            .addCase(getListOfAllLanesByShipments.fulfilled, (state, action) => {
                state.listOfAllLanesByShipmentsDtoLoading = false
                state.listOfAllLanesByShipmentsDto = action.payload
            })
            .addCase(getListOfAllLanesByShipments.rejected, (state, action) => {
                state.listOfAllLanesByShipmentsDtoLoading = isCancelRequest(action?.payload)
                state.listOfAllLanesByShipmentsDto = null
            })

            .addCase(getTruckFuelTypestPieChart.pending, (state) => {
                state.isLoadingTruckFuelTypesPieChart = true
                state.truckFuelTypesPieChart = null
            })
            .addCase(getTruckFuelTypestPieChart.fulfilled, (state, action) => {
                state.isLoadingTruckFuelTypesPieChart = false
                state.truckFuelTypesPieChart = action.payload
            })
            .addCase(getTruckFuelTypestPieChart.rejected, (state, action) => {
                state.isLoadingTruckFuelTypesPieChart = isCancelRequest(action?.payload)
                state.truckFuelTypesPieChart = null
            })
            .addCase(getEmissionIntensityRNGBarChart.pending, (state) => {
                state.isLoadingEmissionIntensityRNGBarChart = true
                state.costEmissionIntensityRNGBarChart = null
            })
            .addCase(getEmissionIntensityRNGBarChart.fulfilled, (state, action) => {
                state.isLoadingEmissionIntensityRNGBarChart = isCancelRequest(action?.payload)
                state.costEmissionIntensityRNGBarChart = action.payload
            })
            .addCase(getEmissionIntensityRNGBarChart.rejected, (state, action) => {
                state.isLoadingEmissionIntensityRNGBarChart = isCancelRequest(action?.payload)
                state.costEmissionIntensityRNGBarChart = null
            })
            .addCase(getKeyMetricsSummaryLocalFreight.pending, (state) => {
                state.isLoadingKeyMetricsSummaryLocalFreight = true
                state.keyMetricsSummaryLocalFreight = null
            })
            .addCase(getKeyMetricsSummaryLocalFreight.fulfilled, (state, action) => {
                state.isLoadingKeyMetricsSummaryLocalFreight = false
                state.keyMetricsSummaryLocalFreight = action.payload
            })
            .addCase(getKeyMetricsSummaryLocalFreight.rejected, (state, action) => {
                state.isLoadingKeyMetricsSummaryLocalFreight = isCancelRequest(action?.payload)
                state.keyMetricsSummaryLocalFreight = null
            })

            .addCase(getLaneNameLocalFreight.pending, (state) => {
                state.isLoadingLaneNameLocalFreight = true
                state.laneNameLocalFreight = null
            })
            .addCase(getLaneNameLocalFreight.fulfilled, (state, action) => {
                state.isLoadingLaneNameLocalFreight = false
                state.laneNameLocalFreight = action.payload
            })
            .addCase(getLaneNameLocalFreight.rejected, (state, action) => {
                state.isLoadingLaneNameLocalFreight = false
                state.laneNameLocalFreight = null
            })
            .addCase(getRNGFreightDate.pending, (state) => {
                state.rngFreightDate = null
            })
            .addCase(getRNGFreightDate.fulfilled, (state, action) => {
                state.rngFreightDate = action.payload
            })
            .addCase(getRNGFreightDate.rejected, (state, action) => {
                state.rngFreightDate = null
            })
    }
});

// Export actions and reducer
export const { resetLocalFreight } = localFreightDataReducer.actions;
export default localFreightDataReducer.reducer;
