// Import the axios library for making HTTP requests
import axios from "axios";

const getTaFuelStopListApi = async (data: any, userToken: { headers: { Authorization: string; }; }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("ta-fuel-stop-list", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};



// Create an object that contains all the facility-related service functions
const taService = {
    getTaFuelStopListApi,
};

// Export the facilityService object as the default export
export default taService;
