// Import the Axios library for making HTTP requests
import axios from "axios";

const getTruckFuelTypesPieChartgApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-rng-data-pie-chart", payload, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getEmissionIntensityRNGBarChartApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-rng-data-graph", payload, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getKeyMetricsSummaryLocalFreightApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("rng-key-metrics", payload, userToken);
        // Return the data from the response
        return response?.data;

    }
    catch (error: any) {
        throw (error);
    }
};

const getLaneNameLocalFreightApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("list-all-rng-lanes", payload, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getRNGFreightDateApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.get("get-min-max-dates-rng", userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getKeyMetricsDateApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.get(`get-metrics?month=${payload?.month || ''}&year=${payload?.year || ''}`, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};


const getListOfAllLanesByShipmentsApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("list-of-all-lanes-by-shipments", payload, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getLanesByFuelUsageAndMileageApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.post("get-lanes-by-fuel-usage-and-mileage", payload, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getStackedGraphByLaneAndFuelTypeApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.get(`get-total-data-by-lane-and-fuel-type?month=${payload?.month || ''}&year=${payload?.year || ''}&column=${payload?.column || ''}`, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getTotalEmissionGraphByLaneAndFuelTypeApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.get(`get-total-emission-by-fuel-type?month=${payload?.month || ''}&year=${payload?.year || ''}&column=${payload?.column || ''}`, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};

const getLaneStatisticsApi = async (payload: any, userToken: { headers: { Authorization: string } }) => {
    try {
        const response = await axios.get(`get-lane-statistics?month=${payload?.month || ''}&year=${payload?.year || ''}&lane_name=${payload?.laneName || ''}`, userToken);
        // Return the data from the response
        return response?.data;
    }
    catch (error: any) {
        throw (error);
    }
};


// Create an object that contains the project-related service functions
const localFreightService = {
    getTruckFuelTypesPieChartgApi,
    getEmissionIntensityRNGBarChartApi,
    getKeyMetricsSummaryLocalFreightApi,
    getLaneNameLocalFreightApi,
    getRNGFreightDateApi,
    getKeyMetricsDateApi,
    getListOfAllLanesByShipmentsApi,
    getLanesByFuelUsageAndMileageApi,
    getStackedGraphByLaneAndFuelTypeApi,
    getTotalEmissionGraphByLaneAndFuelTypeApi,
    getLaneStatisticsApi
};

// Export the projectService object for use in other parts of the application
export default localFreightService;
